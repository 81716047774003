import React from 'react';
import PropTypes from 'prop-types';
import { Box, Flex, HStack, Text, Textarea, Table, Input, Stack, Separator } from '@chakra-ui/react';
import DashboardSectionTile from '../../core/DashboardSectionTile';
import { FieldGroup, POGrinderNo, PreambleText } from '../Components/index';
import moment from 'moment';
import Datetime from 'react-datetime';
import SelectField from '../../basic/SelectField';
import { get, isEmpty, random, set } from 'lodash';
import { useFormikContext } from 'formik';
import { calculateInitialClaimValue } from '../helpers';
import { getOr } from '../../../helpers';
import Constants from '../../../Constants';
import { detailsHStackLayoutProps } from '../Components/index';
import { FaQuestion } from 'react-icons/fa6';
import { useSelector } from 'react-redux';
import { selectEntities } from '../../../slices/masterData/entityManagerSlice';
import { Radio, RadioGroup } from '@/components/ui/radio';
import { Checkbox } from '@/components/ui/checkbox';
import {
  PopoverRoot,
  PopoverContent,
  PopoverBody,
  PopoverTrigger,
  PopoverArrow,
  PopoverCloseTrigger,
} from '@/components/ui/popover';

const dateTimeProps = {
  closeOnSelect: false,
  timeFormat: false,
  dateFormat: 'DD-MMM-YYYY',
  inputProps: {
    style: { height: '40px' },
  },
  isValidDate: current => {
    return current.isAfter(moment().subtract(1, 'day'));
  },
};

const PODetail = ({
  form,
  packerPlants,
  packers,
  unitsOfMeasureOptions,
  onUpdate,
  onUpdateMany,
  editingExistingNCR,
  changeNCR,
  currentNCR,
  nonConformanceTypesOptions,
  nonConformanceSubTypesOptions,
}) => {
  const { values: formikValues, errors } = useFormikContext();
  const filteredNonConformanceSubTypesOptions = nonConformanceSubTypesOptions.filter(
    subCategoryOption => get(formikValues.nonConformanceType, 'value', null) === subCategoryOption.nonConformanceTypeId
  );

  let ncrType = Constants.NCR_TYPES.NCR;
  if (editingExistingNCR) {
    ncrType = get(currentNCR, 'incidentMedia', null) ? Constants.NCR_TYPES.INCIDENT : Constants.NCR_TYPES.NCR;
  } else {
    ncrType = get(form, 'ncrType.key', Constants.NCR_TYPES.NCR);
  }

  const entities = useSelector(selectEntities) || {};

  const tableCols = [
    {
      key: ncrType === Constants.NCR_TYPES.NCR ? 'radio' : 'checkbox',
      label: '',
      name: 'purchaseOrderLineId',
      renderField: props =>
        ncrType === Constants.NCR_TYPES.NCR ? (
          <RadioGroup {...props}>
            <Radio colorScheme="actionPrimary" />
          </RadioGroup>
        ) : (
          <Checkbox {...props} colorScheme="actionPrimary" />
        ),
    },
    {
      key: 'lineNumber',
      label: 'no',
    },
    {
      key: 'inputProduct.name',
      label: 'product name',
    },
    {
      key: 'inputProduct.cl',
      label: 'cl',
    },
    {
      key:
        ncrType === Constants.NCR_TYPES.INCIDENT
          ? editingExistingNCR
            ? 'recivedQuantity'
            : 'recvQuantity'
          : 'recvQuantity',
      label: 'received weight',
    },
    {
      key: 'deliveryDate',
      label: 'delivery date',
    },
    {
      key: '',
      label: '',
    },
    {
      key: ncrType === Constants.NCR_TYPES.INCIDENT ? 'unitOfMeasure' : 'ncrDetails.unitOfMeasure',
      label: 'uom',
      name: ncrType === Constants.NCR_TYPES.INCIDENT ? 'unitOfMeasure' : 'ncrDetails.unitOfMeasure',
      renderField: props => (
        <Box {...props}>
          <SelectField
            containerStyles={{
              width: '129px',
            }}
            menuPortalTarget={document.body}
            options={unitsOfMeasureOptions}
            changeableKey={form}
            {...props}
          />
        </Box>
      ),
    },
    {
      key: 'amountAffected',
      label: 'quantity affected',
      name: 'amountAffected',
      renderField: props => (
        <Flex key={random()} alignItems="center">
          <Input width="129px" type="number" {...props} />
          <PreambleText marginLeft="8px">
            {getOr(
              formikValues,
              'ncrDetails.unitOfMeasure.label',
              getOr(formikValues, 'ncrDetails.unitOfMeasure.name', '')
            )}
          </PreambleText>
        </Flex>
      ),
    },
  ];

  const packerId = get(
    packerPlants.find(packerPlant => packerPlant.id === get(form, 'purchaseOrder.packerPlantId')),
    'packerId',
    null
  );

  const establishmentName = get(
    packerPlants.find(packerPlant => packerPlant.id === get(form, 'purchaseOrder.packerPlantId')),
    'name',
    null
  );

  let purchaseOrders = getOr(form, 'purchaseOrder', []);
  if (!(purchaseOrders instanceof Array)) {
    purchaseOrders = [purchaseOrders];
  }
  purchaseOrders = changeNCR
    ? purchaseOrders.map(po => {
        po.lines = po.lines.filter(line => formikValues.purchaseOrderLineId === line.id);
        return po;
      })
    : purchaseOrders;
  const asianCountries = Constants.asianCountries;

  return (
    <DashboardSectionTile padding="22px 33px 22px 20px" marginTop="8px" fontWeight="400" gap="14px">
      <Box>
        <Text fontSize="16px">
          <b>Grinder:</b>&nbsp;
          {editingExistingNCR
            ? ncrType === Constants.NCR_TYPES.NCR
              ? get(currentNCR, 'grinder.name')
              : get(currentNCR, 'purchaseOrder.grinderName')
            : ncrType === Constants.NCR_TYPES.NCR
            ? getOr(form, 'purchaseOrder.grinder.name', '-')
            : getOr(form, 'purchaseOrder[0].grinder.name', '-')}
        </Text>
        {ncrType !== Constants.NCR_TYPES.INCIDENT && (
          <>
            <Text fontSize="16px">
              <b>Packer:</b>&nbsp;
              {get(
                packers.find(packer => packer.id === packerId),
                'name',
                '-'
              )}
            </Text>
            <Text fontSize="16px">
              <b>Establishment:</b>&nbsp;
              {establishmentName}
            </Text>
          </>
        )}
      </Box>
      <Separator />

      {purchaseOrders?.map((purchaseOrder, poindex) => {
        let showSellQuantityEditIncident;
        // Ensure that `entities.grinder` is not an empty object and has a defined `entities` property
        if (entities.grinder && Array.isArray(entities.grinder.entities)) {
          // Check if the array has at least one entity
          const hasDestinationCountry =
            entities.grinder.entities.length > 0 && entities.grinder.entities[0].destination_country;
          // Determine if `showSellQuantityEditIncident` should be true based on whether `destination_country` exists
          showSellQuantityEditIncident = asianCountries.includes(hasDestinationCountry);
        }
        const packerName = get(
          packers.find(
            packer =>
              packer.id ===
              get(
                packerPlants.find(packerPlant => packerPlant.id === get(purchaseOrder, 'packerPlantId')),
                'packerId',
                null
              )
          ),
          'name',
          ''
        );

        // Check if the grinder's destination country is in the list of Asian countries
        const showSellQuantity = asianCountries.includes(get(purchaseOrder, 'grinder.destinationCountry', ''));

        if (showSellQuantity || showSellQuantityEditIncident) {
          // Define the new object to be added to the table columns
          const showSellQuantityElement = {
            key: 'sellQuantity',
            label: 'Sell Quantity',
          };

          // Ensure `tableCols` is an array before proceeding
          if (Array.isArray(tableCols)) {
            // Find the index of the object with the label "received weight"
            const receivedWeightIndex = tableCols.findIndex(col => col.label === 'received weight');

            if (receivedWeightIndex !== -1) {
              // Remove the object at the identified index
              tableCols.splice(receivedWeightIndex, 1);
            }

            // Add the new object at the same index
            const indexToInsert = receivedWeightIndex !== -1 ? receivedWeightIndex : tableCols.length;
            tableCols.splice(indexToInsert, 0, showSellQuantityElement);
          }
        }
        return (
          <>
            <POGrinderNo
              grinder={
                editingExistingNCR
                  ? ncrType === Constants.NCR_TYPES.NCR
                    ? get(currentNCR, 'ncrDetails.purchaseOrderLine.purchaseOrder.grinderPoNumber')
                    : get(currentNCR, 'purchaseOrder.ginderPo')
                  : getOr(purchaseOrder, 'grinderPoNumber', '-')
              }
              packer_plant={
                editingExistingNCR
                  ? get(
                      packers.find(
                        packer =>
                          packer.id ===
                          get(
                            packerPlants.find(packerPlant => packerPlant.id === get(currentNCR, 'packerPlantId')),
                            'packerId',
                            null
                          )
                      ),
                      'name',
                      ''
                    )
                  : packerName
              }
              isFrozen={
                ncrType === Constants.NCR_TYPES.NCR
                  ? !get(form, 'purchaseOrder.lines[0].chilled')
                  : !get(form, 'purchaseOrder[0].lines[0].chilled')
              }
            />
            <Box width="1028px" paddingBottom="50px" overflow="scroll">
              <Table.Root variant="simple">
                <Table.Header>
                  <Table.Row>
                    {tableCols.map((col, colIndex) => (
                      <Table.ColumnHeader
                        key={col.key}
                        fontSize="12px"
                        padding="10px 12px"
                        borderBottom={colIndex > 5 ? 'none' : '1px solid gray.200'}
                        background={colIndex > 5 ? 'inherit' : ''}
                        backgroundColor="#f7fafc"
                        fontWeight="600"
                        textTransform="uppercase"
                      >
                        {col.label}
                      </Table.ColumnHeader>
                    ))}
                  </Table.Row>
                </Table.Header>
                <Table.Body
                  backgroundColor="#ffffff"
                  fontSize="14px"
                  {...((!isEmpty(errors.purchaseOrderLineId) ||
                    (!isEmpty(errors.purchaseOrderLineIds) && typeof errors.purchaseOrderLineIds === 'string')) && {
                    className: 'error',
                  })}
                >
                  {purchaseOrder.lines?.map(data => (
                    <Table.Row key={poindex + data.id} fontWeight="400">
                      {tableCols.map((col, colIndex) => {
                        // const isSelected = formikValues.purchaseOrderLineId === data.id;
                        const isSelected =
                          ncrType === Constants.NCR_TYPES.NCR
                            ? formikValues.purchaseOrderLineId === data.id
                            : Boolean(
                                formikValues.purchaseOrderLineIds.find(line => line.purchaseOrderLineId === data.id)
                              );
                        let element = get(data, col.key, '');
                        let cellValue = get(data, col.key, null);
                        if (col.key === 'recvQuantity' && cellValue != null) {
                          element = new Intl.NumberFormat(undefined, {
                            notation: 'standard',
                            maximumFractionDigits: 2,
                          }).format(cellValue);
                          element = `${element} ${get(purchaseOrder, 'sellUnitOfMeasure.name')}`;
                        }
                        if (col.key === 'sellQuantity') {
                          element = get(purchaseOrder, 'sellQuantity');
                        }

                        if (col.key === 'recivedQuantity') {
                          const cllValue = get(purchaseOrder, col.key, '');
                          element = new Intl.NumberFormat(undefined, {
                            notation: 'standard',
                            maximumFractionDigits: 2,
                          }).format(cllValue);
                        }

                        if (col.key === 'deliveryDate') {
                          cellValue = get(purchaseOrder, col.key, '');
                          element = moment(cellValue).isValid() ? moment(cellValue).format('DD-MM-YYYY') : '-';
                        }

                        if (col.renderField && col.key !== 'checkbox') {
                          if (ncrType === Constants.NCR_TYPES.INCIDENT) {
                            const purchaseOrderLineIds = get(formikValues, 'purchaseOrderLineIds');
                            const line = purchaseOrderLineIds.find(ln => ln.purchaseOrderLineId === data.id);
                            if (line) {
                              if (col.key === 'amountAffected') {
                                cellValue = line.amountAffected;
                              } else if (col.key === 'unitOfMeasure') {
                                cellValue = line.unitOfMeasure;
                              }
                            }
                          } else {
                            cellValue = get(formikValues, col.name);
                            if (cellValue && cellValue.id && cellValue.name)
                              cellValue = { ...cellValue, value: cellValue.id, label: cellValue.name };
                          }
                          if (cellValue && cellValue.id && cellValue.name)
                            cellValue = { ...cellValue, value: cellValue.id, label: cellValue.name };
                          element = col.renderField({
                            key: col.key + data.id,
                            value: isSelected ? cellValue : '',
                            disabled: !isSelected,
                            onChange: event => {
                              const value = get(event, 'target.value', event);
                              const updateValues = {
                                ...(!editingExistingNCR && {
                                  ncrDetails: {
                                    initialClaimValue: calculateInitialClaimValue(
                                      col.key === 'amountAffected' ? value : get(formikValues, 'amountAffected', 0),
                                      data.sellPricePerUnit,
                                      col.key === 'amountAffected'
                                        ? getOr(
                                            formikValues,
                                            'ncrDetails.unitOfMeasure',
                                            get(form, 'purchaseOrder.sellUnitOfMeasure')
                                          )
                                        : value,
                                      get(form, 'purchaseOrder.sellUnitOfMeasure')
                                    ),
                                  },
                                }),
                              };
                              set(updateValues, col.name, value);
                              onUpdateMany(updateValues);
                              if (ncrType === Constants.NCR_TYPES.INCIDENT) {
                                onUpdate(col.name, value, data.id);
                              }
                            },
                            ...(isSelected && formikValues.highlightInputError
                              ? ncrType === Constants.NCR_TYPES.INCIDENT
                                ? !isEmpty(errors) &&
                                  (cellValue === '' || cellValue === null) && {
                                    className: 'error',
                                  }
                                : !isEmpty(get(errors, col.name)) && {
                                    className: 'error',
                                  }
                              : {}),
                          });
                        }

                        if (col.key === 'checkbox' || col.key === 'radio') {
                          let poId = get(purchaseOrder, 'id', '-');
                          element = col.renderField({
                            onChange: () => {
                              onUpdate(
                                col.name,
                                data.id,
                                null,
                                poId,
                                getOr(purchaseOrder, 'grinderPoNumber', '-'),
                                packerName
                              );
                            },
                            isChecked: isSelected,
                          });
                        }
                        return (
                          <Table.Cell
                            key={col.key + data.id}
                            padding={colIndex <= 8 ? '10px 8px' : '2px 6px'}
                            borderBottom={colIndex > 8 && 'none'}
                            boxShadow={colIndex === 0 || colIndex > 8 ? 'inherit' : '0px 1px 0px 0px #B9B9B9 inset'}
                            backgroundColor={colIndex !== 0 && colIndex <= 8 ? 'inherit' : 'card.default'}
                            width={col.key === 'ncrDetails.unitOfMeasure' && '141px'}
                            opacity={!isSelected && col.key !== 'checkbox' && 0.4}
                          >
                            {element}
                          </Table.Cell>
                        );
                      })}
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table.Root>
            </Box>
            {!editingExistingNCR && ncrType === Constants.NCR_TYPES.INCIDENT && (
              <Box style={{ display: 'flex' }}>
                <Text fontSize="large">Will a potential claim value be added ?</Text>
                <RadioGroup
                  paddingLeft="5"
                  defaultValue="false"
                  value={formikValues.willPotentialClaimValues.find(claim => claim.id === purchaseOrder.id)?.value}
                  onValueChange={e => onUpdate('willMakePotentialClaim', e.value, null, purchaseOrder.id)}
                >
                  <Stack direction={{ lg: 'row' }}>
                    <Radio value="true" colorScheme="actionPrimary" marginBottom={0}>
                      Yes
                    </Radio>
                    <Radio value="false" colorScheme="actionPrimary">
                      No
                    </Radio>
                  </Stack>
                </RadioGroup>
              </Box>
            )}
          </>
        );
      })}
      {editingExistingNCR && ncrType === Constants.NCR_TYPES.INCIDENT && (
        <>
          <HStack {...detailsHStackLayoutProps} gap="32px">
            <FieldGroup label="Category">
              <SelectField
                placeholder="Category"
                name="nonConformanceType"
                value={formikValues.nonConformanceType}
                className={formikValues.highlightInputError && errors.nonConformanceType && 'error'}
                onChange={e => {
                  onUpdateMany({
                    nonConformanceType: e,
                    nonConformanceSubType: null,
                  });
                }}
                options={nonConformanceTypesOptions}
              />
            </FieldGroup>
            <FieldGroup label="Sub-Category">
              <SelectField
                placeholder="Sub-Category"
                name="nonConformanceSubType"
                value={formikValues.nonConformanceSubType}
                className={formikValues.highlightInputError && errors.nonConformanceSubType && 'error'}
                onChange={e => onUpdate('nonConformanceSubType', e)}
                disabled={isEmpty(formikValues.nonConformanceType)}
                options={filteredNonConformanceSubTypesOptions}
              />
            </FieldGroup>
            <FieldGroup label="Entry Date">
              <Datetime
                {...dateTimeProps}
                value={moment(get(formikValues, 'created', undefined))}
                inputProps={{
                  ...dateTimeProps.inputProps,
                  disabled: true,
                }}
              />
            </FieldGroup>
          </HStack>
          <HStack {...detailsHStackLayoutProps} gap="52px" direction={['column', 'row']}>
            <Checkbox
              checked={editingExistingNCR ? get(formikValues, 'isIncident') : get(form, '.ncrDetails.isIncident')}
              onChange={e => onUpdate('isIncident', e.target.checked)}
              marginBottom={0}
            >
              Quality Incident
            </Checkbox>
            <Checkbox
              checked={editingExistingNCR ? get(formikValues, 'isMultiPacker') : get(form, 'ncrDetails.isMultiPacker')}
              className={formikValues.highlightInputError && get(errors, 'ncrDetails.isMultiPacker') && 'error'}
              onChange={e => onUpdate('ncrDetails.isMultiPacker', e.target.checked)}
              marginBottom={0}
              disabled
            >
              Multi-packer incident
            </Checkbox>
            <Checkbox
              checked={
                editingExistingNCR ? get(formikValues, 'ncrDetails.isEntrapment') : get(form, 'ncrDetails.isEntrapment')
              }
              className={formikValues.highlightInputError && get(errors, 'ncrDetails.isEntrapment') && 'error'}
              onChange={e => onUpdate('ncrDetails.isEntrapment', e.target.checked)}
              marginBottom={0}
            >
              Entrapment incident
            </Checkbox>
          </HStack>
          <Box marginTop="42px !important">
            <Flex>
              <label>Details</label>
              <PopoverRoot positioning={{ placement: 'right-end' }}>
                <PopoverTrigger>
                  <FaQuestion boxSize="14px" marginTop="4px" />
                </PopoverTrigger>
                <PopoverContent
                  width="406px"
                  left="8px"
                  _focus={{
                    outline: 'none',
                  }}
                  boxShadow="0px 1.25px 4px rgba(0, 0, 0, 0.25)"
                >
                  <PopoverArrow />
                  <PopoverCloseTrigger top="16px" right="14px" />
                  <PopoverBody padding="16px 46px 16px 19px">
                    <Flex>
                      <FaQuestion boxSize="16px" marginTop="6px" />
                      <Text marginLeft="8px" fontSize="16px">
                        Please provide full and clear information regarding incident finding. Include: what was found,
                        where it was found, how it was found, and what happened as a result.
                      </Text>
                    </Flex>
                  </PopoverBody>
                </PopoverContent>
              </PopoverRoot>
            </Flex>
            <Textarea
              rows={6}
              placeholder="Details"
              name="details"
              value={get(formikValues, 'ncrDetails.details')}
              className={formikValues.highlightInputError && get(errors, 'ncrDetails.details') && 'error'}
              onChange={e => onUpdate('ncrDetails.details', e.target.value)}
            />
          </Box>
          <Box marginTop="4px">
            <PreambleText fontSize="16px">Completed By</PreambleText>
            <HStack {...detailsHStackLayoutProps} gap="32px" marginTop="18px">
              <FieldGroup label="Name">
                <Input value={`${get(currentNCR, 'closedByUserData.name', '')}`} disabled />
              </FieldGroup>
              <FieldGroup label="Role">
                <Input value={get(currentNCR, 'closedByUserData.role')} disabled />
              </FieldGroup>
              <FieldGroup label="Email">
                <Input value={get(currentNCR, 'closedByUserData.email')} disabled />
              </FieldGroup>
              <FieldGroup label="Phone">
                <Input value={get(currentNCR, 'closedByUserData.phone')} disabled />
              </FieldGroup>
            </HStack>
          </Box>
        </>
      )}
    </DashboardSectionTile>
  );
};

PODetail.propTypes = {
  form: PropTypes.shape({
    purchaseOrder: PropTypes.shape({
      grinderPoNumber: PropTypes.string,
      lines: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          chilled: PropTypes.bool,
        })
      ),
    }),
    ncrType: PropTypes.shape({
      key: PropTypes.string,
    }),
  }),
  packerPlants: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      packerId: PropTypes.number.isRequired,
    })
  ),
  packers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    })
  ),
  unitsOfMeasureOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      label: PropTypes.string.isRequired,
    })
  ),
  onUpdate: PropTypes.func,
  onUpdateMany: PropTypes.func,
  editingExistingNCR: PropTypes.func,
  nonConformanceTypesOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      label: PropTypes.string.isRequired,
    })
  ),
  changeNCR: PropTypes.bool,
  nonConformanceSubTypesOptions: PropTypes.arrayOf(
    PropTypes.shape({
      nonConformanceTypeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      label: PropTypes.string.isRequired,
    })
  ),
  currentNCR: PropTypes.shape({
    incidentMedia: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    grinder: PropTypes.shape({
      name: PropTypes.string,
      destinationCountry: PropTypes.string,
    }),
    purchaseOrder: PropTypes.shape({
      grinderName: PropTypes.string,
      ginderPo: PropTypes.string,
    }),
    closedByUserData: PropTypes.shape({
      name: PropTypes.string,
      role: PropTypes.string,
      email: PropTypes.string,
      phone: PropTypes.string,
    }),
    ncrDetails: PropTypes.shape({
      purchaseOrderLine: PropTypes.shape({
        purchaseOrder: PropTypes.shape({
          grinderPoNumber: PropTypes.string,
        }),
      }),
      details: PropTypes.string,
      isIncident: PropTypes.bool,
      isMultiPacker: PropTypes.bool,
      isEntrapment: PropTypes.bool,
    }),
  }),
};

export default PODetail;
