import React, { useEffect } from 'react';
import { Box, Group, HStack, IconButton, Text, VStack, Flex, Separator, Button } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { defaultTo, get } from 'lodash';
import { useFormikContext } from 'formik';
import { Link } from 'react-router';
import Datetime from 'react-datetime';
import { useDispatch } from 'react-redux';
import {
  EditButton,
  FieldGroup,
  PODetails,
  PreambleText,
  VStackLayout,
  detailsHStackLayoutProps,
  OutlineButton,
} from '../Components/index';
import DashboardSectionTile from '../../core/DashboardSectionTile';
import { ReactComponent as DownloadIconSVG } from '../../../assets/download-icon.svg';
import { fetchIncidentsByGroupId } from '../../../actions/action_ncrs';
import ClaimListForm from '../Forms/ClaimListForm';
import { getOr } from '../../../helpers';
import { checkIfIsAdminUser, checkIfIsGrinderUser, checkIfIsPackerUser } from '../../../utils';
import SelectField from '../../basic/SelectField';
import './NCRDetails.scss';
import Constants from '../../../Constants';
import { useChangeReqFor } from '../Context/ChangeReqForContext';
import { Alert } from '../../ui/alert';
import { Checkbox } from '@/components/ui/checkbox';

const DetailContainer = ({ children }) => (
  <VStack align="stretch" gap="28px">
    {children}
  </VStack>
);
DetailContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

const DetailItem = ({ label, detail }) => (
  <Box>
    <PreambleText fontSize="14px">{label}</PreambleText>
    <Text fontSize="14px">{detail}</Text>
  </Box>
);
DetailItem.propTypes = {
  label: PropTypes.string.isRequired,
  detail: PropTypes.string.isRequired,
};

const InvestigatorDetails = ({ currentNCR, userPropertyPath }) => {
  const firstName = get(currentNCR, `${userPropertyPath}.firstName`, '');
  let lastName = get(currentNCR, `${userPropertyPath}.lastName`, '');
  lastName = lastName ? ` ${lastName}` : '';
  const fullName = `${firstName}${lastName}`;
  const role = getOr(currentNCR, `${userPropertyPath}.role.name`, '-');
  const email = getOr(currentNCR, `${userPropertyPath}.email`, '-');
  const contacts = getOr(currentNCR, `${userPropertyPath}.contacts`, []);
  const contact = defaultTo(
    contacts.find(item => item.main),
    contacts[0]
  );
  const phone = getOr(contact, 'number', null)
    ? `${getOr(contact, 'countryCode', null)}${getOr(contact, 'number')}`
    : '-';

  return (
    <>
      <Box>
        <PreambleText fontSize="16px">Completed By:</PreambleText>
        <HStack {...detailsHStackLayoutProps} marginTop="17px">
          <DetailContainer>
            <DetailItem label="Name:" detail={fullName || '-'} />
          </DetailContainer>
          <Separator orientation="vertical" height="8" borderColor="#e4e4e7" />
          <DetailContainer>
            <DetailItem label="Role:" detail={role || '-'} />
          </DetailContainer>
          <Separator orientation="vertical" height="8" borderColor="#e4e4e7" />
          <DetailContainer>
            <DetailItem label="Email" detail={email || '-'} />
          </DetailContainer>
          <Separator orientation="vertical" height="8" borderColor="#e4e4e7" />
          <DetailContainer>
            <DetailItem label="Phone:" detail={phone || '-'} />
          </DetailContainer>
        </HStack>
      </Box>
      <Separator borderColor="#e4e4e7" />
    </>
  );
};

InvestigatorDetails.propTypes = {
  userPropertyPath: PropTypes.string,
  currentNCR: PropTypes.shape({
    ncrDetails: PropTypes.shape({
      willMakeClaim: PropTypes.bool,
      purchaseOrderLine: PropTypes.shape({
        inputProduct: PropTypes.shape({
          uid: PropTypes.string,
          name: PropTypes.string,
          cl: PropTypes.string,
        }),
        recvQuantity: PropTypes.number,
        purchaseOrder: PropTypes.shape({
          deliveryDate: PropTypes.string,
        }),
      }),
      amountAffected: PropTypes.number,
      initialClaimValue: PropTypes.number,
      processStage: PropTypes.string,
      productStatus: PropTypes.string,
      details: PropTypes.string,
      immediateAction: PropTypes.string,
      furtherAction: PropTypes.string,
      initialInvestigation: PropTypes.shape({
        details: PropTypes.string,
        investigationDate: PropTypes.string,
        user: PropTypes.shape({
          firstName: PropTypes.string,
          lastName: PropTypes.string,
          role: PropTypes.shape({
            name: PropTypes.string,
          }),
          email: PropTypes.string,
          contacts: PropTypes.arrayOf(
            PropTypes.shape({
              main: PropTypes.bool,
              countryCode: PropTypes.string,
              number: PropTypes.string,
            })
          ),
        }),
        isAcknowledged: PropTypes.bool,
      }),
      finalInvestigation: PropTypes.shape({
        investigationDate: PropTypes.string,
        rootCause: PropTypes.string,
        correctiveAction: PropTypes.string,
        correctiveActionImplementedDate: PropTypes.string,
        preventiveAction: PropTypes.string,
        preventiveActionImplementedDate: PropTypes.string,
        monitoringRequired: PropTypes.oneOfType([
          PropTypes.bool,
          PropTypes.shape({
            value: PropTypes.bool,
          }),
        ]),
        sendReminderDate: PropTypes.string,
        user: PropTypes.shape({
          firstName: PropTypes.string,
          lastName: PropTypes.string,
          role: PropTypes.shape({
            name: PropTypes.string,
          }),
          email: PropTypes.string,
          contacts: PropTypes.arrayOf(
            PropTypes.shape({
              main: PropTypes.bool,
              countryCode: PropTypes.string,
              number: PropTypes.string,
            })
          ),
        }),
      }),
      completedBy: PropTypes.shape({
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        role: PropTypes.shape({
          name: PropTypes.string,
        }),
        email: PropTypes.string,
        contacts: PropTypes.arrayOf(
          PropTypes.shape({
            main: PropTypes.bool,
            countryCode: PropTypes.string,
            number: PropTypes.string,
          })
        ),
      }),
    }),
    closedByUserData: PropTypes.shape({
      name: PropTypes.string,
      role: PropTypes.string,
      email: PropTypes.string,
      phoneNumber: PropTypes.string,
    }),
    closedDate: PropTypes.string,
    closedByValue: PropTypes.string,
    closedByUser: PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
    }),
    created: PropTypes.string,
    targetClosureDate: PropTypes.string,
    nonConformanceType: PropTypes.shape({
      name: PropTypes.string,
    }),
    nonConformanceSubType: PropTypes.shape({
      category: PropTypes.string,
      name: PropTypes.string,
    }),
    isOnHold: PropTypes.bool,
    isFinishedGoods: PropTypes.bool,
    isIncident: PropTypes.bool,
    isMultiPacker: PropTypes.bool,
    isEntrapment: PropTypes.bool,
    recordNo: PropTypes.string,
    groupId: PropTypes.string,
    claim: PropTypes.shape({
      adminAcceptance: PropTypes.shape({
        value: PropTypes.string,
      }),
      priority: PropTypes.string,
      isUnderReview: PropTypes.bool,
    }),
  }),
};

const UserCompltetedBy = ({ currentNCR }) => {
  const fullName = get(currentNCR, 'closedByUserData.name', '');
  const role = get(currentNCR, 'closedByUserData.role', '');
  const email = get(currentNCR, 'closedByUserData.email', '');
  const phone = get(currentNCR, 'closedByUserData.phoneNumber');

  return (
    <>
      <Box>
        <PreambleText fontSize="16px">Completed By:</PreambleText>
        <HStack {...detailsHStackLayoutProps} marginTop="17px">
          <DetailContainer>
            <DetailItem label="Name:" detail={fullName || '-'} />
          </DetailContainer>
          <DetailContainer>
            <DetailItem label="Role:" detail={role || '-'} />
          </DetailContainer>
          <DetailContainer>
            <DetailItem label="Email" detail={email || '-'} />
          </DetailContainer>
          <DetailContainer>
            <DetailItem label="Phone:" detail={phone || '-'} />
          </DetailContainer>
        </HStack>
      </Box>
      <Separator borderColor="#e4e4e7" />
    </>
  );
};

UserCompltetedBy.propTypes = {
  currentNCR: PropTypes.shape({
    ncrDetails: PropTypes.shape({
      willMakeClaim: PropTypes.bool,
      purchaseOrderLine: PropTypes.shape({
        inputProduct: PropTypes.shape({
          uid: PropTypes.string,
          name: PropTypes.string,
          cl: PropTypes.string,
        }),
        recvQuantity: PropTypes.number,
        purchaseOrder: PropTypes.shape({
          deliveryDate: PropTypes.string,
        }),
      }),
      amountAffected: PropTypes.number,
      initialClaimValue: PropTypes.number,
      processStage: PropTypes.string,
      productStatus: PropTypes.string,
      details: PropTypes.string,
      immediateAction: PropTypes.string,
      furtherAction: PropTypes.string,
      initialInvestigation: PropTypes.shape({
        details: PropTypes.string,
        investigationDate: PropTypes.string,
        user: PropTypes.shape({
          firstName: PropTypes.string,
          lastName: PropTypes.string,
          role: PropTypes.shape({
            name: PropTypes.string,
          }),
          email: PropTypes.string,
          contacts: PropTypes.arrayOf(
            PropTypes.shape({
              main: PropTypes.bool,
              countryCode: PropTypes.string,
              number: PropTypes.string,
            })
          ),
        }),
        isAcknowledged: PropTypes.bool,
      }),
      finalInvestigation: PropTypes.shape({
        investigationDate: PropTypes.string,
        rootCause: PropTypes.string,
        correctiveAction: PropTypes.string,
        correctiveActionImplementedDate: PropTypes.string,
        preventiveAction: PropTypes.string,
        preventiveActionImplementedDate: PropTypes.string,
        monitoringRequired: PropTypes.oneOfType([
          PropTypes.bool,
          PropTypes.shape({
            value: PropTypes.bool,
          }),
        ]),
        sendReminderDate: PropTypes.string,
        user: PropTypes.shape({
          firstName: PropTypes.string,
          lastName: PropTypes.string,
          role: PropTypes.shape({
            name: PropTypes.string,
          }),
          email: PropTypes.string,
          contacts: PropTypes.arrayOf(
            PropTypes.shape({
              main: PropTypes.bool,
              countryCode: PropTypes.string,
              number: PropTypes.string,
            })
          ),
        }),
      }),
      completedBy: PropTypes.shape({
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        role: PropTypes.shape({
          name: PropTypes.string,
        }),
        email: PropTypes.string,
        contacts: PropTypes.arrayOf(
          PropTypes.shape({
            main: PropTypes.bool,
            countryCode: PropTypes.string,
            number: PropTypes.string,
          })
        ),
      }),
    }),
    closedByUserData: PropTypes.shape({
      name: PropTypes.string,
      role: PropTypes.string,
      email: PropTypes.string,
      phoneNumber: PropTypes.string,
    }),
    closedDate: PropTypes.string,
    closedByValue: PropTypes.string,
    closedByUser: PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
    }),
    created: PropTypes.string,
    targetClosureDate: PropTypes.string,
    nonConformanceType: PropTypes.shape({
      name: PropTypes.string,
    }),
    nonConformanceSubType: PropTypes.shape({
      category: PropTypes.string,
      name: PropTypes.string,
    }),
    isOnHold: PropTypes.bool,
    isFinishedGoods: PropTypes.bool,
    isIncident: PropTypes.bool,
    isMultiPacker: PropTypes.bool,
    isEntrapment: PropTypes.bool,
    recordNo: PropTypes.string,
    groupId: PropTypes.string,
    claim: PropTypes.shape({
      adminAcceptance: PropTypes.shape({
        value: PropTypes.string,
      }),
      priority: PropTypes.string,
      isUnderReview: PropTypes.bool,
    }),
  }),
};

const NCRDetails = props => {
  const { values: formikValues, handleSubmit, touched, setTouched } = useFormikContext();
  const { setChangeReqFor } = useChangeReqFor();
  const setChangeReqForValue = value => {
    setChangeReqFor(value);
  };
  const {
    onUpdate,
    onUpdateMany,
    ncrs: { currentNCR, isUpdating, hasUpdated, isIncidentByGroupLoading },
    user,
    isGeneratingPDF,
    setIsGeneratingPDF,
    editDisabled,
    isNCR,
    setShowConnected,
    token,
  } = props;
  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const willMakeClaim = get(currentNCR, 'ncrDetails.willMakeClaim');

  const tableCols = [
    {
      key: 'no',
      label: 'no',
      paths: isNCR ? ['ncrDetails.purchaseOrderLine.inputProduct.uid'] : ['inputProduct.uid'],
    },
    {
      key: 'product_name',
      label: 'product name',
      paths: isNCR ? ['ncrDetails.purchaseOrderLine.inputProduct.name'] : ['inputProduct.name'],
      emptyValue: '-',
    },
    {
      key: 'cl',
      label: 'cl',
      paths: isNCR ? ['ncrDetails.purchaseOrderLine.inputProduct.cl'] : ['inputProduct.cl'],
      emptyValue: '-',
    },
    {
      key: 'received_weight',
      label: 'received weight',
      paths: isNCR ? ['ncrDetails.purchaseOrderLine.recvQuantity'] : ['purchaseOrder.recivedQuantity'],
      emptyValue: '-',
    },
    {
      key: 'delivery_date',
      label: 'delivery date',
      paths: isNCR ? ['ncrDetails.purchaseOrderLine.purchaseOrder.deliveryDate'] : ['purchaseOrder.deliveryDate'],
      emptyValue: '-',
    },
    {
      key: 'quantity_affected',
      label: 'quantity affected',
      paths: isNCR ? ['amountAffected'] : ['quantityAffected'],
      emptyValue: '-',
    },
    ...(willMakeClaim
      ? [
          {
            key: 'claim_value',
            label: 'claim value',
            paths: ['claim.claimEntityFinalTotal', 'claim.claimEntityInitialTotal', 'ncrDetails.initialClaimValue'],
            emptyValue: '-',
          },
        ]
      : []),
  ];

  const isPackerUser = checkIfIsPackerUser(user);
  const isGrinderUser = checkIfIsGrinderUser(user);

  const initialInvestigationDetails = getOr(currentNCR, 'ncrDetails.initialInvestigation.details');
  const initialInvestigationDate = get(currentNCR, 'ncrDetails.initialInvestigation.investigationDate', null);
  const finalInvestigationDate = get(currentNCR, 'ncrDetails.finalInvestigation.investigationDate', null);
  const correctiveActionImplementedDate = get(
    currentNCR,
    'ncrDetails.finalInvestigation.correctiveActionImplementedDate',
    null
  );
  const preventiveActionImplementedDate = get(
    currentNCR,
    'ncrDetails.finalInvestigation.preventiveActionImplementedDate',
    null
  );

  const createdDate = get(currentNCR, 'created', null);
  const targetClosureDate = get(currentNCR, 'ncrDetails.targetClosureDate', null);
  const isEditingMonitoring = get(formikValues, 'isEditingMonitoring');
  const isEditingClosure = get(formikValues, 'isEditingClosure');
  const closureIsEditable = !willMakeClaim && initialInvestigationDate && initialInvestigationDetails;
  const isAdminUser = checkIfIsAdminUser(user);
  // const releaseToPacker = get(currentNCR, 'releaseToPacker', null);
  const adminAcceptance = get(currentNCR, 'claim.adminAcceptance.value', null);
  const claimStatus = get(currentNCR, 'claim.priority', null);
  const isUnderReviewClaim = get(currentNCR, 'claim.isUnderReview', null);

  const monitoringRequiredOptions = [
    {
      value: true,
      label: 'Yes',
    },
    {
      value: false,
      label: 'No',
    },
  ];

  const closureOptions = Constants.NCR_ADMIN_GRINDER_CLOSURE_OPTIONS;

  const monitoringRequired = getOr(
    formikValues,
    'ncrDetails.finalInvestigation.monitoringRequired.value',
    getOr(formikValues, 'ncrDetails.finalInvestigation.monitoringRequired', null)
  );

  const closedDate = get(currentNCR, 'closedDate', null);
  const closedByValue = closureOptions.find(
    closureOption => closureOption.value === get(currentNCR, 'closedByValue', null)
  );

  const closedByUser = get(currentNCR, 'closedByUser');

  const url = isNCR
    ? `/edit-ncr/${get(currentNCR, 'recordNo')}?startEditTabKey=po_details`
    : `/edit-incident/${get(currentNCR, 'recordNo')}?startEditTabKey=po_details`;

  const fullHostname = window.location.hostname;

  return (
    <Box id="divToPrint" className={isGeneratingPDF && 'downloading'}>
      <DashboardSectionTile
        title={isNCR ? 'NCR Details' : 'INCIDENT Details'}
        padding="22px 33px 22px 20px"
        marginTop="2px"
        position="relative"
        borderTopLeftRadius={0}
        tileStatusComponent={() => (
          <Group gap="22px">
            {!isNCR ? (
              <OutlineButton
                onClick={() => {
                  setShowConnected(get(currentNCR, 'recordNo'));
                  dispatch(fetchIncidentsByGroupId(token, get(currentNCR, 'groupId')));
                }}
                isLoading={isIncidentByGroupLoading}
              >
                Show connected Incidents
              </OutlineButton>
            ) : (
              ''
            )}
            <IconButton
              colorScheme="actionPrimary"
              variant="outline"
              minW="70px"
              height="40px"
              disabled={get(formikValues, 'isEditing')}
              onClick={() => {
                setIsGeneratingPDF(true);
              }}
            >
              <DownloadIconSVG />
            </IconButton>
            {(isGrinderUser || isAdminUser) && (
              <Link to={url}>
                <EditButton disabled={editDisabled} />
              </Link>
            )}
          </Group>
        )}
        headerFlexStyle={{
          justifyContent: 'space-between',
        }}
      >
        <VStackLayout backgroundColor="white">
          <PODetails overviewNCR={currentNCR} tableCols={tableCols} showTotal={willMakeClaim} isNCR={isNCR} />
          <HStack gap="40px">
            <HStack {...detailsHStackLayoutProps} borderRightWidth="1px">
              <DetailContainer>
                <DetailItem
                  label="Category:"
                  detail={get(currentNCR, 'nonConformanceType.name', get(currentNCR, 'nonConformanceSubType.category'))}
                />
                {isNCR ? <DetailItem label="Process Stage:" detail={get(currentNCR, 'ncrDetails.processStage')} /> : ''}
              </DetailContainer>
              <Separator orientation="vertical" height="28" borderColor="#e4e4e7" />
              <DetailContainer>
                <DetailItem label="Sub-Category:" detail={get(currentNCR, 'nonConformanceSubType.name')} />
                {isNCR && <DetailItem label="Product Status:" detail={get(currentNCR, 'ncrDetails.productStatus')} />}
              </DetailContainer>
              <Separator orientation="vertical" height="28" borderColor="#e4e4e7" />
              <DetailContainer>
                <DetailItem
                  label="Entry Date:"
                  detail={moment(createdDate).isValid() ? moment(createdDate).format('DD-MMM-YYYY') : '-'}
                />
                {isNCR && (
                  <DetailItem
                    label="Target Closure Date:"
                    detail={moment(targetClosureDate).isValid() ? moment(targetClosureDate).format('DD-MMM-YYYY') : '-'}
                  />
                )}
              </DetailContainer>
            </HStack>
            {isNCR ? (
              <Box alignSelf="flex-start">
                <Flex>
                  <PreambleText fontSize="14px">Is there raw material on hold?</PreambleText>
                  <Text marginLeft="10px">{get(currentNCR, 'ncrDetails.isOnHold') ? 'Yes' : 'No'}</Text>
                </Flex>
                <Flex>
                  <PreambleText fontSize="14px">Are there finished goods on hold?</PreambleText>
                  <Text marginLeft="10px">{get(currentNCR, 'ncrDetails.isFinishedGoods') ? 'Yes' : 'No'}</Text>
                </Flex>
              </Box>
            ) : (
              <Box alignSelf="flex-start">
                <Flex>
                  <PreambleText fontSize="14px">Quality Incident</PreambleText>
                  <Text marginLeft="10px">{get(currentNCR, 'isIncident') ? 'Yes' : 'No'}</Text>
                </Flex>
                <Flex>
                  <PreambleText fontSize="14px">Multi Packer Incident</PreambleText>
                  <Text marginLeft="10px">{get(currentNCR, 'isMultiPacker') ? 'Yes' : 'No'}</Text>
                </Flex>
                <Flex>
                  <PreambleText fontSize="14px">Entrapment Incident</PreambleText>
                  <Text marginLeft="10px">{get(currentNCR, 'isEntrapment') ? 'Yes' : 'No'}</Text>
                </Flex>
              </Box>
            )}
          </HStack>
        </VStackLayout>
        <VStackLayout>
          <>
            <Box>
              <PreambleText fontSize="16px">Details:</PreambleText>
              {isNCR ? (
                <Text fontSize="14px">{getOr(currentNCR, 'ncrDetails.details', '-')}</Text>
              ) : (
                <Text fontSize="14px">{getOr(currentNCR, 'details', '-')}</Text>
              )}
            </Box>
            <Separator borderColor="#e4e4e7" />
          </>
          {isNCR ? (
            <InvestigatorDetails currentNCR={currentNCR} userPropertyPath="ncrDetails.completedBy" />
          ) : (
            <UserCompltetedBy currentNCR={currentNCR} />
          )}
          {isNCR && (
            <>
              <Box>
                <PreambleText fontSize="16px">Immediate Actions:</PreambleText>
                <Text fontSize="14px">{getOr(currentNCR, 'ncrDetails.immediateAction', '-')}</Text>
              </Box>
              <Box>
                <PreambleText fontSize="16px">Further Actions:</PreambleText>
                <Text fontSize="14px">{getOr(currentNCR, 'ncrDetails.furtherAction', '-')}</Text>
              </Box>
            </>
          )}
        </VStackLayout>
      </DashboardSectionTile>
      {isNCR ? (
        <DashboardSectionTile
          title={`${willMakeClaim ? 'Initial ' : ''}Investigation`}
          padding="22px 33px 22px 20px"
          marginTop="40px"
          tileStatusComponent={() =>
            isPackerUser ? (
              <Link
                to={`/edit-ncr/${get(currentNCR, 'recordNo')}?startEditTabKey=initial_investigation`}
                onClick={() => setChangeReqForValue('edit_initial_investigation')}
              >
                <EditButton disabled={editDisabled || isUnderReviewClaim} />
              </Link>
            ) : (
              ''
            )
          }
          headerFlexStyle={{
            justifyContent: 'space-between',
          }}
        >
          <VStackLayout>
            <InvestigatorDetails currentNCR={currentNCR} userPropertyPath="ncrDetails.initialInvestigation.user" />
            <Box>
              <PreambleText fontSize="16px">{willMakeClaim ? 'Initial ' : ''}Investigation Date:</PreambleText>
              <Text>
                {moment(initialInvestigationDate).isValid()
                  ? moment(initialInvestigationDate).format('DD-MMM-YYYY')
                  : '-'}
              </Text>
            </Box>
            <Separator borderColor="#e4e4e7" />
            <Box>
              <PreambleText fontSize="16px">{willMakeClaim ? 'Initial ' : ''}Investigation Details:</PreambleText>
              <Text>{initialInvestigationDetails || '-'}</Text>
            </Box>
            {willMakeClaim && (
              <Box>
                <Checkbox
                  colorScheme="actionSecondary"
                  checked={get(currentNCR, 'ncrDetails.initialInvestigation.isAcknowledged')}
                  pointerEvents="none"
                >
                  I acknowledge the receipt of this NCR. This is our agreed initial response for the facility. Final
                  response will be completed in required timeframe.
                </Checkbox>
              </Box>
            )}

            {closedByValue ? (
              <Box>
                {fullHostname.includes('agilechain') ? (
                  <PreambleText fontSize="14px">NCR Closed by CFC or Grinder</PreambleText>
                ) : (
                  <PreambleText fontSize="14px">NCR Closed by FMG or Grinder</PreambleText>
                )}
                {closedByValue ? (
                  <Text>
                    <b>Closed</b>
                    &nbsp;by&nbsp;
                    {closedByUser ? `${get(closedByUser, 'firstName')} ${get(closedByUser, 'lastName')}` : '-'}
                    &nbsp;on&nbsp;
                    {moment(closedDate).isValid() ? moment(closedDate).format('DD-MMM-YYYY') : '-'}
                  </Text>
                ) : (
                  '-'
                )}
              </Box>
            ) : (
              ''
            )}

            {!closedByValue && !willMakeClaim && closureIsEditable && (isAdminUser || isGrinderUser) ? (
              <Flex justifyContent="space-between">
                <FieldGroup
                  label={
                    fullHostname.includes('agilechain')
                      ? 'NCR Closed by CFC or Grinder'
                      : 'NCR Closed by FMG or Grinder'
                  }
                >
                  <SelectField
                    placeholder="Select"
                    name="claimAcceptance"
                    value={closureOptions.filter(
                      closureOption =>
                        getOr(formikValues, 'closedByValue.value', getOr(formikValues, 'closedByValue', null)) ===
                        closureOption.value
                    )}
                    onChange={e => onUpdate('closedByValue', e)}
                    options={closureOptions}
                    disabled={!isEditingClosure}
                  />
                </FieldGroup>
                <Group alignSelf="flex-end">
                  {isEditingClosure ? (
                    <Button
                      variant="outline"
                      colorScheme="actionPrimary"
                      fontWeight="700"
                      width="108px"
                      height="40px"
                      onClick={() => {
                        handleSubmit();
                      }}
                      loading={isUpdating}
                    >
                      Submit
                    </Button>
                  ) : (
                    <EditButton onClick={() => onUpdate('isEditingClosure', true)} disabled={editDisabled} />
                  )}
                </Group>
              </Flex>
            ) : (
              ''
            )}
          </VStackLayout>
        </DashboardSectionTile>
      ) : (
        ''
      )}
      {willMakeClaim ? (
        <>
          <DashboardSectionTile
            title="Final Investigation"
            padding="22px 33px 22px 20px"
            marginTop="40px"
            tileStatusComponent={() =>
              isPackerUser ? (
                <Link
                  to={`/edit-ncr/${get(currentNCR, 'recordNo')}?startEditTabKey=final_investigation`}
                  onClick={() => setChangeReqForValue('edit_final_investigation')}
                >
                  <EditButton disabled={editDisabled || isUnderReviewClaim} />
                </Link>
              ) : (
                ''
              )
            }
            headerFlexStyle={{
              justifyContent: 'space-between',
            }}
          >
            <VStackLayout>
              <InvestigatorDetails currentNCR={currentNCR} userPropertyPath="ncrDetails.finalInvestigation.user" />
              <Box>
                <PreambleText fontSize="16px">Final Investigation Date:</PreambleText>
                <Text>
                  {moment(finalInvestigationDate).isValid()
                    ? moment(finalInvestigationDate).format('DD-MMM-YYYY')
                    : '-'}
                </Text>
              </Box>
              <Separator borderColor="#e4e4e7" />
              <Box>
                <PreambleText fontSize="16px">Root Cause Identified as:</PreambleText>
                <Text>{getOr(currentNCR, `ncrDetails.finalInvestigation.rootCause`, '-')}</Text>
              </Box>
              <Separator borderColor="#e4e4e7" />
              <Box>
                <PreambleText fontSize="16px">Corrective Actions:</PreambleText>
                <Text>{getOr(currentNCR, `ncrDetails.finalInvestigation.correctiveAction`, '-')}</Text>
              </Box>

              <Box>
                {' '}
                <PreambleText fontSize="16px">Date Implemented:</PreambleText>
                <Text>
                  {moment(correctiveActionImplementedDate).isValid()
                    ? moment(correctiveActionImplementedDate).format('DD-MMM-YYYY')
                    : '-'}
                </Text>
              </Box>
              <Separator borderColor="#e4e4e7" />
              <Box>
                <PreambleText fontSize="16px">Preventive Actions:</PreambleText>
                <Text>{getOr(currentNCR, `ncrDetails.finalInvestigation.preventiveAction`, '-')}</Text>
              </Box>

              <Box>
                <PreambleText fontSize="16px">Date Implemented:</PreambleText>
                <Text>
                  {moment(preventiveActionImplementedDate).isValid()
                    ? moment(preventiveActionImplementedDate).format('DD-MMM-YYYY')
                    : '-'}
                </Text>
              </Box>
              <Separator borderColor="#e4e4e7" />
              {isAdminUser ? (
                <Box>
                  <Flex justifyContent="space-between">
                    <Box>
                      <Flex>
                        <FieldGroup
                          label={
                            fullHostname.includes('agilechain')
                              ? 'CFC CA/PA monitoring required:'
                              : 'FMG CA/PA monitoring required:'
                          }
                        >
                          <SelectField
                            placeholder="Select"
                            name="deletion_reason"
                            value={monitoringRequiredOptions.find(
                              monitoringRequiredOption => monitoringRequiredOption.value === monitoringRequired
                            )}
                            onChange={e => {
                              const updateValues = {
                                ncrDetails: {
                                  finalInvestigation: {
                                    monitoringRequired: e,
                                    ...(e.value && { sendReminderDate: moment().add(14, 'days').format() }),
                                  },
                                },
                              };
                              onUpdateMany(updateValues);
                            }}
                            options={monitoringRequiredOptions}
                            disabled={!isEditingMonitoring}
                          />
                        </FieldGroup>
                        <FieldGroup label="Send Reminder on:" marginLeft="45px">
                          <Datetime
                            id="start_date"
                            closeOnSelect
                            timeFormat={false}
                            dateFormat="YYYY-MM-DD"
                            inputProps={{
                              disabled: !isEditingMonitoring,
                            }}
                            value={moment(get(formikValues, 'ncrDetails.finalInvestigation.sendReminderDate', null))}
                            onChange={value => {
                              const { _isAMomentObject: isAMomentObject } = value;
                              onUpdate(
                                'ncrDetails.finalInvestigation.sendReminderDate',
                                isAMomentObject ? value.format() : null
                              );
                            }}
                          />
                        </FieldGroup>
                      </Flex>
                    </Box>
                    <Group alignSelf="flex-end">
                      {isEditingMonitoring ? (
                        <Button
                          variant="outline"
                          colorScheme="actionPrimary"
                          fontWeight="700"
                          width="108px"
                          height="40px"
                          onClick={() => {
                            handleSubmit();
                          }}
                          loading={isUpdating}
                        >
                          Save
                        </Button>
                      ) : (
                        <EditButton onClick={() => onUpdate('isEditingMonitoring', true)} disabled={editDisabled} />
                      )}
                    </Group>
                  </Flex>
                  {hasUpdated &&
                  !isEditingMonitoring &&
                  (get(touched, 'ncrDetails.finalInvestigation.monitoringRequired') ||
                    get(touched, 'ncrDetails.finalInvestigation.sendReminderDate')) ? (
                    <Alert
                      status="success"
                      marginTop="23px"
                      // variant="left-accent"
                      borderRadius="6px"
                      fontWeight="400"
                      fontSize="16px"
                      paddingY="16px"
                      width="fit-content"
                      title="Changes have been successfully changed"
                      closeable
                      onClose={() => {
                        setTouched({}, false);
                      }}
                    />
                  ) : (
                    ''
                  )}
                </Box>
              ) : (
                ''
              )}
            </VStackLayout>
          </DashboardSectionTile>
          {!isPackerUser ||
          (isPackerUser &&
            (claimStatus === Constants.CLAIM_STATUSES.MAJOR ||
              adminAcceptance === 'accepted' ||
              adminAcceptance === 'reviewed')) ? (
            <Box marginTop="40px">
              <ClaimListForm isReviewing {...props} />
            </Box>
          ) : (
            ''
          )}
        </>
      ) : (
        ''
      )}
    </Box>
  );
};

NCRDetails.propTypes = {
  onUpdate: PropTypes.func,
  onUpdateMany: PropTypes.func,
  ncrs: PropTypes.shape({
    currentNCR: PropTypes.shape({
      ncrDetails: PropTypes.shape({
        willMakeClaim: PropTypes.bool,
        purchaseOrderLine: PropTypes.shape({
          inputProduct: PropTypes.shape({
            uid: PropTypes.string,
            name: PropTypes.string,
            cl: PropTypes.string,
          }),
          recvQuantity: PropTypes.number,
          purchaseOrder: PropTypes.shape({
            deliveryDate: PropTypes.string,
          }),
        }),
        amountAffected: PropTypes.number,
        initialClaimValue: PropTypes.number,
        processStage: PropTypes.string,
        productStatus: PropTypes.string,
        details: PropTypes.string,
        immediateAction: PropTypes.string,
        furtherAction: PropTypes.string,
        initialInvestigation: PropTypes.shape({
          details: PropTypes.string,
          investigationDate: PropTypes.string,
          user: PropTypes.shape({
            firstName: PropTypes.string,
            lastName: PropTypes.string,
            role: PropTypes.shape({
              name: PropTypes.string,
            }),
            email: PropTypes.string,
            contacts: PropTypes.arrayOf(
              PropTypes.shape({
                main: PropTypes.bool,
                countryCode: PropTypes.string,
                number: PropTypes.string,
              })
            ),
          }),
          isAcknowledged: PropTypes.bool,
        }),
        finalInvestigation: PropTypes.shape({
          investigationDate: PropTypes.string,
          rootCause: PropTypes.string,
          correctiveAction: PropTypes.string,
          correctiveActionImplementedDate: PropTypes.string,
          preventiveAction: PropTypes.string,
          preventiveActionImplementedDate: PropTypes.string,
          monitoringRequired: PropTypes.oneOfType([
            PropTypes.bool,
            PropTypes.shape({
              value: PropTypes.bool,
            }),
          ]),
          sendReminderDate: PropTypes.string,
          user: PropTypes.shape({
            firstName: PropTypes.string,
            lastName: PropTypes.string,
            role: PropTypes.shape({
              name: PropTypes.string,
            }),
            email: PropTypes.string,
            contacts: PropTypes.arrayOf(
              PropTypes.shape({
                main: PropTypes.bool,
                countryCode: PropTypes.string,
                number: PropTypes.string,
              })
            ),
          }),
        }),
        completedBy: PropTypes.shape({
          firstName: PropTypes.string,
          lastName: PropTypes.string,
          role: PropTypes.shape({
            name: PropTypes.string,
          }),
          email: PropTypes.string,
          contacts: PropTypes.arrayOf(
            PropTypes.shape({
              main: PropTypes.bool,
              countryCode: PropTypes.string,
              number: PropTypes.string,
            })
          ),
        }),
      }),
      closedByUserData: PropTypes.shape({
        name: PropTypes.string,
        role: PropTypes.string,
        email: PropTypes.string,
        phoneNumber: PropTypes.string,
      }),
      closedDate: PropTypes.string,
      closedByValue: PropTypes.string,
      closedByUser: PropTypes.shape({
        firstName: PropTypes.string,
        lastName: PropTypes.string,
      }),
      created: PropTypes.string,
      targetClosureDate: PropTypes.string,
      nonConformanceType: PropTypes.shape({
        name: PropTypes.string,
      }),
      nonConformanceSubType: PropTypes.shape({
        category: PropTypes.string,
        name: PropTypes.string,
      }),
      isOnHold: PropTypes.bool,
      isFinishedGoods: PropTypes.bool,
      isIncident: PropTypes.bool,
      isMultiPacker: PropTypes.bool,
      isEntrapment: PropTypes.bool,
      recordNo: PropTypes.string,
      groupId: PropTypes.string,
      claim: PropTypes.shape({
        adminAcceptance: PropTypes.shape({
          value: PropTypes.string,
        }),
        priority: PropTypes.string,
        isUnderReview: PropTypes.bool,
      }),
    }),
    isUpdating: PropTypes.bool,
    hasUpdated: PropTypes.bool,
    isIncidentByGroupLoading: PropTypes.bool,
  }),
  user: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    role: PropTypes.shape({
      name: PropTypes.string,
    }),
  }),
  token: PropTypes.string,
  isGeneratingPDF: PropTypes.bool,
  setIsGeneratingPDF: PropTypes.func,
  setShowConnected: PropTypes.func,
  editDisabled: PropTypes.bool,
  isNCR: PropTypes.bool,
};

export default NCRDetails;
