import React from 'react';
import { useField } from 'formik';
import Datetime from 'react-datetime';

const DateField = ({ name }) => {
  const [field, meta, helpers] = useField(name);
  const { setValue } = helpers;

  const onChange = (type, value) => {
    setValue(value);
  };

  return (
    <Datetime
      id={name}
      inputProps={{
        className: 'form-control m-input',
      }}
      closeOnSelect
      value={meta.value}
      onChange={e => onChange(name, e._isAMomentObject ? e.format('YYYY-MM-DD') : null)}
      timeFormat={false}
      dateFormat="YYYY-MM-DD"
    />
  );
};
export default DateField;
